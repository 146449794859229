<template>
  <div class="admin-write">
    <div class="f-r f-ac f-js">
      <admin-back-button />
      <button @click="publish()" class="f-r f-ac button" style="position: relative;">
        <div v-if="loading" class="spinner" style="position: absolute; left: 10px;"></div>
        <p style="margin: 0 10px">Publicera</p>
      </button>
    </div>
    <div v-show="!showingPreview" class="admin-write-panel shadow"  @submit.prevent="">
      <label for="title" class="text--dark" style="margin-bottom: 4px; display: block">Titel</label>
      <input name="title" type="text" style="margin-bottom: 20px" v-model="title">

      <label class="text--dark" style="margin-bottom: 4px; display: block">Innehåll</label>
      <div id="editor" ref="editor" style="margin-bottom: 20px"></div>

      <label class="text--dark" style="margin-bottom: 4px; display: block">Taggar</label>
      <div class="f-r f-ac" style="margin-bottom: 20px; flex-wrap: wrap; row-gap: 10px;">
        <el-tag
          :key="tag"
          v-for="tag in tags"
          closable
          :disable-transitions="false"
          
          @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="tagInputVisible"
          v-model="tagInputValue"
          ref="saveTagInput"
          size="mini"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ Ny tagg</el-button>
      </div>

      <label class="text--dark" style="margin-bottom: 4px; display: block">Bilder</label>
      <div class="admin-write-img-container">
        <!-- <button style=""></button> -->
        <button class="button" @click="$refs.fileInput.click()" style="margin-bottom: 20px">Ladda upp bild</button>
        <input type="file" ref="fileInput" style="display: none;"  @change="(e) => addImage(e, false)">
        <input type="file" ref="fileInputInText" style="display: none;"  @change="(e) => addImage(e, true)">

        <div class="admin-write-img-list f-r f-ac">
          <div v-for="i in images" :key="i">
            <img :src="i" class="admin-write-img">
            <p class="hoverable text--dark text-c" @click="removeImage(i)">Ta bort</p>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import AdminBackButton from '../../components/admin/AdminBackButton.vue'
import Quill from 'quill'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';

import pica from 'pica'
import processImage from '../../store/processImage'

export default {
  components: { AdminBackButton },
  data() {
    return {
      editor: null,
      
      title: '',
      content: null,
      images: [],
      inTextImages: [],
      loadingImage: false,
      loading: false,
      showingPreview: false,
      tags: [],
      tagInputVisible: false,
      tagInputValue: ''
    }
  },
  props: {
    editModel: {}
  },
  methods: {
    async addImage(event, inText) {
      this.loadingImage = true

      const file = await processImage(event.target.files[0])
      
      const url = await this.$store.dispatch('uploadFile', { folder: 'posts-images', file: file, uid: event.timeStamp.toString() })
      if(inText) {
        this.inTextImages.push(url)
        const range = this.editor.getSelection();
        this.editor.insertEmbed(range.index, 'image', url, Quill.sources.USER);
      }
      else this.images.push(url)
      this.loadingImage = false
    },
    async removeImage(url) {
      this.$store.dispatch('removeFile', { url: url })
      this.images.splice(this.images.indexOf(url), 1)
    },
    async publish() {
      this.loading = true

      // Trim inTextImages for images no longer in the post
      for(const image in this.inTextImages) {
        if(!this.editor.root.innerHTML.includes(image)) {
          const url = this.inTextImages[image]
          this.$store.dispatch('removeFile', { url: url })
          this.inTextImages.splice(this.inTextImages.indexOf(url), 1)
          console.log('Trimmed unused image: ' + url)
        }
      }

      const timestamp = new Date()

      const time = this.editModel ? this.editModel.time : timestamp.toISOString().replace('.', '+')
      const dateString = this.editModel ? this.editModel.dateString : timestamp.toLocaleDateString('sv', { year:"numeric", month:"long", day:"numeric"})
      const title = this.title
      const plain = this.editor.getText()
      const inner = this.editor.root.innerHTML
      const images = this.images
      const inTextImages = this.inTextImages ?? []
      const tags = this.tags
      const slug = this.editModel?.title == this.title ? this.editModel.slug : this.slug()


      const post = {
        'time': time,
        'dateString': dateString,
        'title': title,
        'plain': plain,
        'inner': inner,
        'images': images,
        'inTextImages': inTextImages,
        'tags': tags,
        'slug': slug,
      }

      await this.$store.dispatch('publishPost', { post, time })

      this.loading = false
      this.$router.go(-1)
    },
    slug() {
      let slug = this.title
        .replace(/[åäÅÄ]/g, 'a')
        .replace(/[öÖ]/g, 'o')
        .replace(/\s/g, "-")
        .replace(/[^a-zA-Z0-9\-]/g, "")
        .trim()
        .toLowerCase()

      //Test slug for collisions
      let i = 2
      while(this.slugCollides(slug)) {
        slug = `${slug}-${i}`
        i++
      }

      return slug
    },
    slugCollides(slug) {
      for(const data of Object.entries(this.$store.state.posts)) {
        if(slug === data[1].slug) return true
      }
      return false
    },
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },

    showInput() {
      this.tagInputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.tagInputValue;
      if (inputValue) {
        this.tags.push(inputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = '';
    }
  },
  mounted() {

    this.editor = new Quill(this.$refs.editor, {
      theme: 'snow',
      modules: {
        toolbar: {
          container: [[{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['link', 'image']],
          handlers: {
            image: () => {
              this.$refs.fileInputInText.click()
            }
          }
        },
      },
    })

    if(this.editModel) {
      this.title = this.editModel.title
      this.editor.root.innerHTML = this.editModel.inner
      this.images = this.editModel.images
      this.inTextImages = this.editModel.inTextImages
      this.tags = this.editModel.tags
    }
  },

}
</script>

<style scoped>

.admin-write {
  margin: 0 auto;
  max-width: 1000px;
}

.admin-write-panel {
  background-color: var(--c-background);
  padding: 20px 20px;
  margin: 20px 0;
  box-sizing: border-box;
}

#editor >>> p, #editor >>> h1, #editor >>> h2, #editor >>> h3, #editor >>> li {
  color: var(--c-text-dark);
}
#editor >>> img {
  max-width: none;
  width: 100%;
  height: auto;
}

.admin-write-panel >>> button {
  box-shadow: none;
}

#editor {
  font-family: "Open Sans", sans-serif;
}

.admin-write-img-list {
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
}

.admin-write-img {
  max-width: none;
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
</style>